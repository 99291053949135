<template>
  <v-dialog v-model="showModal" class="testing" width="400">
    <v-card>
      <v-card-title class="headline error white--text" primary-title> Are you sure? </v-card-title>
      <v-card-text class="my-4"> Latest vaccine taken will be deleted. </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelBtnPressed"> No </v-btn>
        <v-btn color="error" @click="deleteVaccine" :loading="deleting"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['showModal', 'dosage', 'currentVaccineNumber'],
  data: () => ({
    deleting: false
  }),
  methods: {
    cancelBtnPressed() {
      this.$emit('hideConfirmDialog');
    },
    async deleteVaccine() {
      try {
        this.deleting = true;
        const result = await this.$store.dispatch('deleteVaccineDosage', this.dosage.id);
        if (result) {
          let payload = {
            open: true,
            text: `Dosage ${this.currentVaccineNumber} has been deleted.`,
            color: 'success'
          };
          this.$store.commit('SET_SNACKBAR', payload);
          this.$emit('dosageDeleted');
        } 
        else this.$store.dispatch('showError', 'Error deleting dosage.');
      } catch (error) {
        this.$store.dispatch('showError', 'Error deleting dosage.');
      } finally {
        this.deleting = false;
      }
    }
  }
};
</script>
