import { render, staticRenderFns } from "./DeleteDosageModal.vue?vue&type=template&id=ce4ebb04&"
import script from "./DeleteDosageModal.vue?vue&type=script&lang=js&"
export * from "./DeleteDosageModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ce4ebb04')) {
      api.createRecord('ce4ebb04', component.options)
    } else {
      api.reload('ce4ebb04', component.options)
    }
    module.hot.accept("./DeleteDosageModal.vue?vue&type=template&id=ce4ebb04&", function () {
      api.rerender('ce4ebb04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/VaccineViews/components/DeleteDosageModal.vue"
export default component.exports