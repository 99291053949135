<template>
  <div>
    <v-card class="dosage-card mb-3" outlined>
      <v-card-text class="pa-3 mx-4">
        <v-row>
          <v-col class="pl-0">
            <div class="overline mb-0"><v-icon>mdi-needle</v-icon> Dosage {{ vaccineNumber }}</div>
          </v-col>
          <v-col>
            <v-avatar class="avatar-wrapper pa-0" size="30" style="position: absolute; right: 12px; top: 20px" v-if="vaccineDate">
              <v-icon class="ma-0" color="green" large>mdi-check-circle</v-icon>
            </v-avatar>
          </v-col>
          <v-col v-if="dosage">
            <v-icon
              v-if="dosage"
              @click="deleteDosage"
              v-bind:color="getIconStateColor"
              class="ma-0"
              style="font-size: 1.8em; position: absolute; right: 52px; top: 22px"
              large
              >mdi-delete
            </v-icon>
          </v-col>
        </v-row>
        <v-row>
          <div>
            <div class="mb-0 mt-2">
              <span class="label">Appointment {{ vaccineNumber }}: </span>
              <span class="value" v-if="appointment">
                {{ formatDateTime(appointment) }}
                <v-chip small color="success" class="ms-2" v-if="isToday(appointment)">Today</v-chip>
                <v-chip small color="info" class="ms-2" v-if="isAfterToday(appointment)">{{ daysBeforeTillToday(appointment) }} days left</v-chip>
                <v-chip small class="ms-2 error--text red lighten-5" v-if="isBeforeToday(appointment)">
                  {{ daysAfterTillToday(appointment) }} days ago
                </v-chip>
              </span>
              <span class="value" v-else>No appointment set</span>
            </div>

            <div class="mb-0 d-flex flex-column" v-if="dosage">
              <div class="label mb-0">Batch Number:</div>
              <span class="caption mt-0 pt-0 box-number value">{{ dosage.inventory.boxNumber }}</span>
            </div>

            <div class="mb-0 d-flex" v-if="dosage && dosage.inventory">
              <div class="label mb-0 mr-1">Brand:</div>
              <span class="mt-0 pt-0 value">{{ dosage.inventory.brand.brandName }}</span>
            </div>

            <div class="mb-0 d-flex" v-if="dosage">
              <span class="label mr-1">Location: </span>
              <span class="value">
                {{ dosage.inventory.location.name }}
              </span>
            </div>
            <div class="mb-0 d-flex" v-if="vaccineDate">
              <span class="label">Dosage {{ vaccineNumber }} taken on: </span>
              <span class="value">
                {{ formatDateTime(vaccineDate) }}
              </span>
            </div>
            <div class="mb-0 d-flex" v-if="dosage && dosage.certifyBy">
              <div class="label mr-1">Certified by:</div>
              <div class="value">{{ dosage.certifyBy }}</div>
            </div>
          </div>
        </v-row>
      </v-card-text>
    </v-card>

    <DeleteDosageModal
      :showModal="confirmDialog"
      :dosage="dosage"
      :currentVaccineNumber="vaccineNumber"
      v-on:hideConfirmDialog="hideConfirmDialog"
      v-on:dosageDeleted="dosageDeleted"
    />
  </div>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import DeleteDosageModal from '@/views/VaccineViews/components/DeleteDosageModal.vue';

export default {
  mixins: [SharedFunctions],
  props: ['dosage', 'appointment', 'vaccineNumber', 'vaccineDate', 'isLastTaken', 'hasGroupDeleteDosagePermission'],
  components: {
    DeleteDosageModal
  },
  data: () => ({
    confirmDialog: false,
    canNotDeleteDialog: false,
    test: false,
  }),
  computed: {
    getIconStateColor() {
      const enabled = 'grey darken-1';
      const disabled = 'grey lighten-1';
      return this.isLastTaken && this.hasGroupDeleteDosagePermission ? enabled : disabled;
    }
  },
  methods: {
    deleteDosage() {
      if (this.isLastTaken) 
      {
        if(!this.hasGroupDeleteDosagePermission)
          this.$store.dispatch('showError', 'User needs group permission to delete dosage.');
        else
          this.confirmDialog = true;
      }
      else this.$store.dispatch('showError', 'You can only delete the latest dosage taken.');
    },
    hideConfirmDialog() {
      this.confirmDialog = false;
    },
    dosageDeleted() {
      this.confirmDialog = false;
      this.$emit('refresh');
    }
  }
};
</script>

<style lang="scss">
.dosage-card {
  background: #f9f9f9 !important;
  border: 1px solid darken(#f5f5f5, 8%) !important;
  border-radius: 10px !important;
  .avatar-wrapper {
    background: white;
    border-radius: 100px !important;
    display: flex;
    align-content: center;
    justify-items: center;
    i {
      font-size: 2rem;
    }
  }
  .box-number {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
  }

  .v-icon {
    color: lighten(grey, 20%);
    margin-right: 5px;
  }
  .v-card__text {
    .overline {
      color: #366baf;
      font-size: 14px !important;
      font-weight: 500;
    }
    .label {
      color: #626262;
      display: block;
    }
    .value {
      color: #1e1e1e;
      display: block;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
}
</style>